@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1825px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1637px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1057px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1824px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1636px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1056px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1057px) and (max-width: 1636px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1637px) and (max-width: 1824px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/* CSS Document */
/*====================================================================

	helper.css

=====================================================================*/
/* display */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (max-width: 375px) {
  .d-xxs-less-none {
    display: none !important;
  }
  .d-xxs-less-inline {
    display: inline !important;
  }
  .d-xxs-less-inline-block {
    display: inline-block !important;
  }
  .d-xxs-less-block {
    display: block !important;
  }
  .d-xxs-less-table {
    display: table !important;
  }
  .d-xxs-less-table-row {
    display: table-row !important;
  }
  .d-xxs-less-table-cell {
    display: table-cell !important;
  }
  .d-xxs-less-flex {
    display: flex !important;
  }
  .d-xxs-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 376px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 575px) {
  .d-xs-less-none {
    display: none !important;
  }
  .d-xs-less-inline {
    display: inline !important;
  }
  .d-xs-less-inline-block {
    display: inline-block !important;
  }
  .d-xs-less-block {
    display: block !important;
  }
  .d-xs-less-table {
    display: table !important;
  }
  .d-xs-less-table-row {
    display: table-row !important;
  }
  .d-xs-less-table-cell {
    display: table-cell !important;
  }
  .d-xs-less-flex {
    display: flex !important;
  }
  .d-xs-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 767px) {
  .d-sm-less-none {
    display: none !important;
  }
  .d-sm-less-inline {
    display: inline !important;
  }
  .d-sm-less-inline-block {
    display: inline-block !important;
  }
  .d-sm-less-block {
    display: block !important;
  }
  .d-sm-less-table {
    display: table !important;
  }
  .d-sm-less-table-row {
    display: table-row !important;
  }
  .d-sm-less-table-cell {
    display: table-cell !important;
  }
  .d-sm-less-flex {
    display: flex !important;
  }
  .d-sm-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 1056px) {
  .d-md-less-none {
    display: none !important;
  }
  .d-md-less-inline {
    display: inline !important;
  }
  .d-md-less-inline-block {
    display: inline-block !important;
  }
  .d-md-less-block {
    display: block !important;
  }
  .d-md-less-table {
    display: table !important;
  }
  .d-md-less-table-row {
    display: table-row !important;
  }
  .d-md-less-table-cell {
    display: table-cell !important;
  }
  .d-md-less-flex {
    display: flex !important;
  }
  .d-md-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1057px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 1636px) {
  .d-lg-less-none {
    display: none !important;
  }
  .d-lg-less-inline {
    display: inline !important;
  }
  .d-lg-less-inline-block {
    display: inline-block !important;
  }
  .d-lg-less-block {
    display: block !important;
  }
  .d-lg-less-table {
    display: table !important;
  }
  .d-lg-less-table-row {
    display: table-row !important;
  }
  .d-lg-less-table-cell {
    display: table-cell !important;
  }
  .d-lg-less-flex {
    display: flex !important;
  }
  .d-lg-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1637px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (max-width: 1824px) {
  .d-xl-less-none {
    display: none !important;
  }
  .d-xl-less-inline {
    display: inline !important;
  }
  .d-xl-less-inline-block {
    display: inline-block !important;
  }
  .d-xl-less-block {
    display: block !important;
  }
  .d-xl-less-table {
    display: table !important;
  }
  .d-xl-less-table-row {
    display: table-row !important;
  }
  .d-xl-less-table-cell {
    display: table-cell !important;
  }
  .d-xl-less-flex {
    display: flex !important;
  }
  .d-xl-less-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1825px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

.d-less-none {
  display: none !important;
}

.d-less-inline {
  display: inline !important;
}

.d-less-inline-block {
  display: inline-block !important;
}

.d-less-block {
  display: block !important;
}

.d-less-table {
  display: table !important;
}

.d-less-table-row {
  display: table-row !important;
}

.d-less-table-cell {
  display: table-cell !important;
}

.d-less-flex {
  display: flex !important;
}

.d-less-inline-flex {
  display: inline-flex !important;
}

/* img */
a.light-box {
  display: block;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

a.light-box:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.light-box::after {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease 0s;
  color: #fff;
  z-index: 1;
}

a.light-box img {
  transition: all 0.3s ease 0s;
}

a.light-box:hover img {
  transform: scale(1.1, 1.1);
  filter: brightness(50%);
}

a.light-box:hover::after {
  font-size: 3rem;
  opacity: 1;
}

a.img-scale figure {
  overflow: hidden;
}

a.img-scale img {
  transition: all 0.3s ease 0s;
}

a.img-scale:hover img {
  transform: scale(1.1, 1.1);
}

.img-aspect {
  position: relative;
  width: 100%;
}

.img-aspect::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}

.img-aspect > div,
.img-aspect figure,
.img-aspect a,
.img-aspect picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* ofi */
.ofi-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.ofi-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.of-hidden {
  overflow: hidden;
}

/* color */
.cl--main {
  color: #1D2087;
}

.cl--red {
  color: #CE0000;
}

.cl--blue {
  color: #113AAC;
}

.required {
  display: inline-block;
  padding: 0 .3em;
  color: #fff;
  background: #CE0000;
  font-size: 1em;
}

@media (min-width: 576px) {
  .required {
    font-size: 0.93em;
  }
}

@media (min-width: 1057px) {
  .required {
    font-size: 0.87em;
  }
}

/* font */
.fs-s {
  font-size: 0.875em;
}

.fs-m {
  font-size: 1.14em;
}

.fs-l {
  font-size: 1.25em;
}

.fs-xl {
  font-size: 1.5em;
}

.fs--14 {
  font-size: 0.88rem;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700 !important;
}

.f-font01 {
  font-family: 'Noto Sans JP', sans-serif;
}

.f-font02 {
  font-family: 'Montserrat', sans-serif;
}

.f-font03 {
  font-family: 'Roboto', sans-serif;
}

/* txt-align */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (max-width: 375px) {
  .text .text-xxs-less-left {
    text-align: left !important;
  }
  .text .text-xxs-less-right {
    text-align: right !important;
  }
  .text .text-xxs-less-center {
    text-align: center !important;
  }
}

@media (min-width: 376px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}

@media (max-width: 575px) {
  .text .text-xs-less-left {
    text-align: left !important;
  }
  .text .text-xs-less-right {
    text-align: right !important;
  }
  .text .text-xs-less-center {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .text .text-sm-less-left {
    text-align: left !important;
  }
  .text .text-sm-less-right {
    text-align: right !important;
  }
  .text .text-sm-less-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (max-width: 1056px) {
  .text .text-md-less-left {
    text-align: left !important;
  }
  .text .text-md-less-right {
    text-align: right !important;
  }
  .text .text-md-less-center {
    text-align: center !important;
  }
}

@media (min-width: 1057px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (max-width: 1636px) {
  .text .text-lg-less-left {
    text-align: left !important;
  }
  .text .text-lg-less-right {
    text-align: right !important;
  }
  .text .text-lg-less-center {
    text-align: center !important;
  }
}

@media (min-width: 1637px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (max-width: 1824px) {
  .text .text-xl-less-left {
    text-align: left !important;
  }
  .text .text-xl-less-right {
    text-align: right !important;
  }
  .text .text-xl-less-center {
    text-align: center !important;
  }
}

@media (min-width: 1825px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text .text-less-left {
  text-align: left !important;
}

.text .text-less-right {
  text-align: right !important;
}

.text .text-less-center {
  text-align: center !important;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

/* botton */
.btn-normal--main {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #1D2087;
  border: 1px solid #1D2087;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-normal--main:hover {
  color: #fff;
  background-color: #0E0F53;
  border-color: #0E0F53;
}

.btn-normal--black {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-normal--black:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #120CBC;
}

.btn-arrow--main {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #1D2087;
  border: 1px solid #1D2087;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-arrow--main:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-arrow--main::after {
  position: absolute;
  right: 1rem;
  color: #fff;
  font-size: 0.9em;
  transition: all 0.3s ease 0s;
  top: 50%;
  transform: translateY(-50%);
}

.btn-arrow--main:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #120CBC;
}

.btn-arrow--main:hover::after {
  color: #fff;
  right: 0.7rem;
}

.btn-arrow--white {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-arrow--white:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-arrow--white::after {
  position: absolute;
  right: 1rem;
  color: #000;
  font-size: 0.9em;
  transition: all 0.3s ease 0s;
  top: 50%;
  transform: translateY(-50%);
}

.btn-arrow--white:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-arrow--white:hover::after {
  color: #fff;
  right: 0.7rem;
}

.btn-arrow--sub {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #0E0F53;
  border: 1px solid #fff;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-arrow--sub:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-arrow--sub::after {
  position: absolute;
  right: 1rem;
  color: #fff;
  font-size: 0.9em;
  transition: all 0.3s ease 0s;
  top: 50%;
  transform: translateY(-50%);
}

.btn-arrow--sub:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #fff;
}

.btn-arrow--sub:hover::after {
  color: #fff;
  right: 0.7rem;
}

.btn-arrow--black {
  font-weight: normal;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #fff;
  background-color: #0E0F53;
  border: 1px solid #0E0F53;
  padding: 1.5rem 4%;
  width: 100%;
}

.btn-arrow--black:after {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-arrow--black::after {
  position: absolute;
  right: 1rem;
  color: #fff;
  font-size: 0.9em;
  transition: all 0.3s ease 0s;
  top: 50%;
  transform: translateY(-50%);
}

.btn-arrow--black:hover {
  color: #fff;
  background-color: #120CBC;
  border-color: #120CBC;
}

.btn-arrow--black:hover::after {
  color: #fff;
  right: 0.7rem;
}

/* margin */
.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.m-1 {
  margin: 0.5rem;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem;
}

.m-2 {
  margin: 0.75rem;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem;
}

.m-3 {
  margin: 1rem;
}

.mt-3,
.my-3 {
  margin-top: 1rem;
}

.mr-3,
.mx-3 {
  margin-right: 1rem;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem;
}

.ml-3,
.mx-3 {
  margin-left: 1rem;
}

.m-4 {
  margin: 2rem;
}

.mt-4,
.my-4 {
  margin-top: 2rem;
}

.mr-4,
.mx-4 {
  margin-right: 2rem;
}

.mb-4,
.my-4 {
  margin-bottom: 2rem;
}

.ml-4,
.mx-4 {
  margin-left: 2rem;
}

.m-5 {
  margin: 5rem;
}

.mt-5,
.my-5 {
  margin-top: 5rem;
}

.mr-5,
.mx-5 {
  margin-right: 5rem;
}

.mb-5,
.my-5 {
  margin-bottom: 5rem;
}

.ml-5,
.mx-5 {
  margin-left: 5rem;
}

.m-6 {
  margin: 9rem;
}

.mt-6,
.my-6 {
  margin-top: 9rem;
}

.mr-6,
.mx-6 {
  margin-right: 9rem;
}

.mb-6,
.my-6 {
  margin-bottom: 9rem;
}

.ml-6,
.mx-6 {
  margin-left: 9rem;
}

.m-7 {
  margin: 12rem;
}

.mt-7,
.my-7 {
  margin-top: 12rem;
}

.mr-7,
.mx-7 {
  margin-right: 12rem;
}

.mb-7,
.my-7 {
  margin-bottom: 12rem;
}

.ml-7,
.mx-7 {
  margin-left: 12rem;
}

.p-0 {
  padding: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

.p-1 {
  padding: 0.5rem;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem;
}

.p-2 {
  padding: 0.75rem;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem;
}

.p-3 {
  padding: 1rem;
}

.pt-3,
.py-3 {
  padding-top: 1rem;
}

.pr-3,
.px-3 {
  padding-right: 1rem;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem;
}

.pl-3,
.px-3 {
  padding-left: 1rem;
}

.p-4 {
  padding: 2rem;
}

.pt-4,
.py-4 {
  padding-top: 2rem;
}

.pr-4,
.px-4 {
  padding-right: 2rem;
}

.pb-4,
.py-4 {
  padding-bottom: 2rem;
}

.pl-4,
.px-4 {
  padding-left: 2rem;
}

.p-5 {
  padding: 5rem;
}

.pt-5,
.py-5 {
  padding-top: 5rem;
}

.pr-5,
.px-5 {
  padding-right: 5rem;
}

.pb-5,
.py-5 {
  padding-bottom: 5rem;
}

.pl-5,
.px-5 {
  padding-left: 5rem;
}

.p-6 {
  padding: 9rem;
}

.pt-6,
.py-6 {
  padding-top: 9rem;
}

.pr-6,
.px-6 {
  padding-right: 9rem;
}

.pb-6,
.py-6 {
  padding-bottom: 9rem;
}

.pl-6,
.px-6 {
  padding-left: 9rem;
}

.p-7 {
  padding: 12rem;
}

.pt-7,
.py-7 {
  padding-top: 12rem;
}

.pr-7,
.px-7 {
  padding-right: 12rem;
}

.pb-7,
.py-7 {
  padding-bottom: 12rem;
}

.pl-7,
.px-7 {
  padding-left: 12rem;
}

@media (min-width: 376px) {
  .m-xs-0 {
    margin: 0;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0;
  }
  .m-xs-1 {
    margin: 0.5rem;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.5rem;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.5rem;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.5rem;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.5rem;
  }
  .m-xs-2 {
    margin: 0.75rem;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.75rem;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.75rem;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.75rem;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.75rem;
  }
  .m-xs-3 {
    margin: 1rem;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem;
  }
  .m-xs-4 {
    margin: 2rem;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 2rem;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 2rem;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 2rem;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 2rem;
  }
  .m-xs-5 {
    margin: 5rem;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 5rem;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 5rem;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 5rem;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 5rem;
  }
  .m-xs-6 {
    margin: 9rem;
  }
  .mt-xs-6,
  .my-xs-6 {
    margin-top: 9rem;
  }
  .mr-xs-6,
  .mx-xs-6 {
    margin-right: 9rem;
  }
  .mb-xs-6,
  .my-xs-6 {
    margin-bottom: 9rem;
  }
  .ml-xs-6,
  .mx-xs-6 {
    margin-left: 9rem;
  }
  .m-xs-7 {
    margin: 12rem;
  }
  .mt-xs-7,
  .my-xs-7 {
    margin-top: 12rem;
  }
  .mr-xs-7,
  .mx-xs-7 {
    margin-right: 12rem;
  }
  .mb-xs-7,
  .my-xs-7 {
    margin-bottom: 12rem;
  }
  .ml-xs-7,
  .mx-xs-7 {
    margin-left: 12rem;
  }
  .p-xs-0 {
    padding: 0;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0;
  }
  .p-xs-1 {
    padding: 0.5rem;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.5rem;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.5rem;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.5rem;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.5rem;
  }
  .p-xs-2 {
    padding: 0.75rem;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.75rem;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.75rem;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.75rem;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.75rem;
  }
  .p-xs-3 {
    padding: 1rem;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem;
  }
  .p-xs-4 {
    padding: 2rem;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 2rem;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 2rem;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 2rem;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 2rem;
  }
  .p-xs-5 {
    padding: 5rem;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 5rem;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 5rem;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 5rem;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 5rem;
  }
  .p-xs-6 {
    padding: 9rem;
  }
  .pt-xs-6,
  .py-xs-6 {
    padding-top: 9rem;
  }
  .pr-xs-6,
  .px-xs-6 {
    padding-right: 9rem;
  }
  .pb-xs-6,
  .py-xs-6 {
    padding-bottom: 9rem;
  }
  .pl-xs-6,
  .px-xs-6 {
    padding-left: 9rem;
  }
  .p-xs-7 {
    padding: 12rem;
  }
  .pt-xs-7,
  .py-xs-7 {
    padding-top: 12rem;
  }
  .pr-xs-7,
  .px-xs-7 {
    padding-right: 12rem;
  }
  .pb-xs-7,
  .py-xs-7 {
    padding-bottom: 12rem;
  }
  .pl-xs-7,
  .px-xs-7 {
    padding-left: 12rem;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0;
  }
  .m-sm-1 {
    margin: 0.5rem;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem;
  }
  .m-sm-2 {
    margin: 0.75rem;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem;
  }
  .m-sm-3 {
    margin: 1rem;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem;
  }
  .m-sm-4 {
    margin: 2rem;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem;
  }
  .m-sm-5 {
    margin: 5rem;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem;
  }
  .m-sm-6 {
    margin: 9rem;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 9rem;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 9rem;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 9rem;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 9rem;
  }
  .m-sm-7 {
    margin: 12rem;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 12rem;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 12rem;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 12rem;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 12rem;
  }
  .p-sm-0 {
    padding: 0;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0;
  }
  .p-sm-1 {
    padding: 0.5rem;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem;
  }
  .p-sm-2 {
    padding: 0.75rem;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem;
  }
  .p-sm-3 {
    padding: 1rem;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem;
  }
  .p-sm-4 {
    padding: 2rem;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem;
  }
  .p-sm-5 {
    padding: 5rem;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem;
  }
  .p-sm-6 {
    padding: 9rem;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 9rem;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 9rem;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 9rem;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 9rem;
  }
  .p-sm-7 {
    padding: 12rem;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 12rem;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 12rem;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 12rem;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 12rem;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0;
  }
  .m-md-1 {
    margin: 0.5rem;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem;
  }
  .m-md-2 {
    margin: 0.75rem;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem;
  }
  .m-md-3 {
    margin: 1rem;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem;
  }
  .m-md-4 {
    margin: 2rem;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem;
  }
  .m-md-5 {
    margin: 5rem;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem;
  }
  .m-md-6 {
    margin: 9rem;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 9rem;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 9rem;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 9rem;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 9rem;
  }
  .m-md-7 {
    margin: 12rem;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 12rem;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 12rem;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 12rem;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 12rem;
  }
  .p-md-0 {
    padding: 0;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0;
  }
  .p-md-1 {
    padding: 0.5rem;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem;
  }
  .p-md-2 {
    padding: 0.75rem;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem;
  }
  .p-md-3 {
    padding: 1rem;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem;
  }
  .p-md-4 {
    padding: 2rem;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem;
  }
  .p-md-5 {
    padding: 5rem;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem;
  }
  .p-md-6 {
    padding: 9rem;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 9rem;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 9rem;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 9rem;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 9rem;
  }
  .p-md-7 {
    padding: 12rem;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 12rem;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 12rem;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 12rem;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 12rem;
  }
}

@media (min-width: 1057px) {
  .m-lg-0 {
    margin: 0;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0;
  }
  .m-lg-1 {
    margin: 0.5rem;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem;
  }
  .m-lg-2 {
    margin: 0.75rem;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem;
  }
  .m-lg-3 {
    margin: 1rem;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem;
  }
  .m-lg-4 {
    margin: 2rem;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem;
  }
  .m-lg-5 {
    margin: 5rem;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem;
  }
  .m-lg-6 {
    margin: 9rem;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 9rem;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 9rem;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 9rem;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 9rem;
  }
  .m-lg-7 {
    margin: 12rem;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 12rem;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 12rem;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 12rem;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 12rem;
  }
  .p-lg-0 {
    padding: 0;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0;
  }
  .p-lg-1 {
    padding: 0.5rem;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem;
  }
  .p-lg-2 {
    padding: 0.75rem;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem;
  }
  .p-lg-3 {
    padding: 1rem;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem;
  }
  .p-lg-4 {
    padding: 2rem;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem;
  }
  .p-lg-5 {
    padding: 5rem;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem;
  }
  .p-lg-6 {
    padding: 9rem;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 9rem;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 9rem;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 9rem;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 9rem;
  }
  .p-lg-7 {
    padding: 12rem;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 12rem;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 12rem;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 12rem;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 12rem;
  }
}

@media (min-width: 1637px) {
  .m-xl-0 {
    margin: 0;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0;
  }
  .m-xl-1 {
    margin: 0.5rem;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem;
  }
  .m-xl-2 {
    margin: 0.75rem;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem;
  }
  .m-xl-3 {
    margin: 1rem;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem;
  }
  .m-xl-4 {
    margin: 2rem;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem;
  }
  .m-xl-5 {
    margin: 5rem;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem;
  }
  .m-xl-6 {
    margin: 9rem;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 9rem;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 9rem;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 9rem;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 9rem;
  }
  .m-xl-7 {
    margin: 12rem;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 12rem;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 12rem;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 12rem;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 12rem;
  }
  .p-xl-0 {
    padding: 0;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0;
  }
  .p-xl-1 {
    padding: 0.5rem;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem;
  }
  .p-xl-2 {
    padding: 0.75rem;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem;
  }
  .p-xl-3 {
    padding: 1rem;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem;
  }
  .p-xl-4 {
    padding: 2rem;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem;
  }
  .p-xl-5 {
    padding: 5rem;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem;
  }
  .p-xl-6 {
    padding: 9rem;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 9rem;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 9rem;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 9rem;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 9rem;
  }
  .p-xl-7 {
    padding: 12rem;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 12rem;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 12rem;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 12rem;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 12rem;
  }
}

@media (min-width: 1825px) {
  .m-xxl-0 {
    margin: 0;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0;
  }
  .m-xxl-1 {
    margin: 0.5rem;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.5rem;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.5rem;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.5rem;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.5rem;
  }
  .m-xxl-2 {
    margin: 0.75rem;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.75rem;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.75rem;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.75rem;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.75rem;
  }
  .m-xxl-3 {
    margin: 1rem;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem;
  }
  .m-xxl-4 {
    margin: 2rem;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 2rem;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 2rem;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 2rem;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 2rem;
  }
  .m-xxl-5 {
    margin: 5rem;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 5rem;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 5rem;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 5rem;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 5rem;
  }
  .m-xxl-6 {
    margin: 9rem;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 9rem;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 9rem;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 9rem;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 9rem;
  }
  .m-xxl-7 {
    margin: 12rem;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 12rem;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 12rem;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 12rem;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 12rem;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0;
  }
  .p-xxl-1 {
    padding: 0.5rem;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.5rem;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.5rem;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.5rem;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.5rem;
  }
  .p-xxl-2 {
    padding: 0.75rem;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.75rem;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.75rem;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.75rem;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.75rem;
  }
  .p-xxl-3 {
    padding: 1rem;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem;
  }
  .p-xxl-4 {
    padding: 2rem;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 2rem;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 2rem;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 2rem;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 2rem;
  }
  .p-xxl-5 {
    padding: 5rem;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 5rem;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 5rem;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 5rem;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 5rem;
  }
  .p-xxl-6 {
    padding: 9rem;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 9rem;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 9rem;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 9rem;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 9rem;
  }
  .p-xxl-7 {
    padding: 12rem;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 12rem;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 12rem;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 12rem;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 12rem;
  }
}

.m-a {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 375px) {
  .m-a-xxs-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 376px) {
  .m-a-xs {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .m-a-xs-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .m-a-sm {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .m-a-sm-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .m-a-md {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1056px) {
  .m-a-md-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1057px) {
  .m-a-lg {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1636px) {
  .m-a-lg-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1637px) {
  .m-a-xl {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1824px) {
  .m-a-xl-less {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1825px) {
  .m-a-xxl {
    margin-left: auto;
    margin-right: auto;
  }
}

.m-a-less {
  margin-left: auto;
  margin-right: auto;
}

/* max-width */
.w-280 {
  max-width: 280px;
}

.w-320 {
  max-width: 320px;
}

.w-360 {
  max-width: 360px;
}

.w-480 {
  max-width: 480px;
}

.w-800 {
  max-width: 800px;
}

.w-960 {
  max-width: 960px;
}

.w-1040 {
  max-width: 1040px;
}

.w-100p {
  width: 100%;
}

/* height */
.h-100 {
  height: 100%;
}

/* flexbox */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 376px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1057px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1637px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1825px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 376px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333%;
  }
  .offset-xs-2 {
    margin-left: 16.66667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333%;
  }
  .offset-xs-5 {
    margin-left: 41.66667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333%;
  }
  .offset-xs-8 {
    margin-left: 66.66667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333%;
  }
  .offset-xs-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1057px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1637px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1825px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1057px) {
  .container {
    max-width: 1040px;
  }
}

@media (min-width: 1637px) {
  .container {
    max-width: 1620px;
  }
}

@media (min-width: 1825px) {
  .container {
    max-width: 1808px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row > [class*="col-"] img {
  width: 100%;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
